import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";

import TicketListDummyLoader from "ticket/components/TicketListDummyLoader";
import AgAlertList from "./AgAlertList";
import AlertBulkActionUpload from "./AlertBulkActionUpload";

import { fetchAlertList } from "gis_alert/data/alert.actions";
import { useSearchParams } from "react-router-dom";

const AlertList = () => {
  /**
   * Parent:
   *    AlertAdminPage
   */
  const [searchParams, setSearchParams] = useSearchParams();
  const alertStatus = searchParams.get("status") || "P";

  const { isLoading, data: alertListData } = useQuery(
    ["alertList", alertStatus],
    fetchAlertList,
    {
      refetchInterval: 60000, // 60 sec
    }
  );

  const handleAlertStatusUpdate = useCallback(
    (newStatus) => () => {
      setSearchParams({ status: newStatus });
    },
    []
  );

  return (
    <Stack height="100%" divider={<Divider flexItem />}>
      <Stack p={2} direction="row" spacing={2} width="100%" alignItems="center">
        <Typography flex={1} className="dtl-title" color="primary" variant="h5">
          Alerts :
        </Typography>

        <Stack spacing={1} direction="row">
          <LoadingButton
            variant="outlined"
            disabled={alertStatus === "P"}
            loading={alertStatus === "P" && isLoading}
            onClick={handleAlertStatusUpdate("P")}
          >
            Problem
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            disabled={alertStatus === "R"}
            loading={alertStatus === "R" && isLoading}
            onClick={handleAlertStatusUpdate("R")}
          >
            Resolved
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            disabled={alertStatus === "C"}
            loading={alertStatus === "C" && isLoading}
            onClick={handleAlertStatusUpdate("C")}
          >
            Closed
          </LoadingButton>

          <AlertBulkActionUpload />
        </Stack>
      </Stack>

      {isLoading ? (
        <TicketListDummyLoader />
      ) : (
        <AgAlertList
          viewAs="normal"
          alertStatus={alertStatus}
          alertList={alertListData}
        />
      )}
    </Stack>
  );
};

export default AlertList;
