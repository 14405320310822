import React from "react";
import { useQuery } from "react-query";

import get from "lodash/get";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { fetchActiveUserCount } from "gis_user/data/services";

const ActiveUserCount = () => {
  const { isLoading, data } = useQuery("activeUserCount", fetchActiveUserCount);

  return (
    <Tooltip
      title={
        <Stack fontSize="1.2em">
          <Box>
            <b>Web</b>: {get(data, "web_count", "--")}
          </Box>
          <Box>
            <b>Mobile</b>: {get(data, "mobile_count", "--")}
          </Box>
        </Stack>
      }
    >
      <Typography variant="caption">
        ( Active Users : {isLoading ? "--" : get(data, "user_count", "--")} /{" "}
        {get(data, "max_count", "--")} )
      </Typography>
    </Tooltip>
  );
};

export default ActiveUserCount;
