import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";

import get from "lodash/get";
import has from "lodash/has";
import find from "lodash/find";

import {
  Box,
  Stack,
  Button,
  Divider,
  Typography,
  Skeleton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { FormCheckbox } from "components/common/FormFields";
import { FormSelect } from "components/common/FormFields";

import { addNotification } from "redux/reducers/notification.reducer";
import { fetchUserRoles, updateUserPerm } from "gis_user/data/services";
import { parseErrorMessagesWithFields } from "utils/api.utils";
import { AVAILABLE_DASHBOARDS } from "pages/Dashboard/dashboard.utils";

export const USER_LAYER_PERMS_CONFIG = [
  { name: "Distribution Point", layerKey: "p_dp" },
  { name: "Spliter", layerKey: "p_splitter" },
  { name: "Cable", layerKey: "p_cable" },
  { name: "Survey building", layerKey: "p_survey_building" },
  { name: "Survey area", layerKey: "p_survey_area" },
  { name: "Pop Location", layerKey: "p_pop" },
  { name: "Sub Pop Location", layerKey: "p_spop" },
  { name: "FMS", layerKey: "p_fms" },
  { name: "Feeder Service Area", layerKey: "p_fsa" },
  { name: "OLT", layerKey: "p_olt" },
  { name: "Distribution Service Area", layerKey: "p_dsa" },
  { name: "Customer Service Area", layerKey: "p_csa" },
  { name: "Pole", layerKey: "p_pole" },
  { name: "Chamber", layerKey: "p_manhole" },
  { name: "Joint Closer", layerKey: "p_jointcloser" },
  { name: "FDC", layerKey: "p_fdc" },
  { name: "ONU", layerKey: "p_onu" },
  { name: "Customer", layerKey: "p_customer" },
  { name: "Switch", layerKey: "p_switch" },
  { name: "Ipqam", layerKey: "p_ipqam" },
  { name: "Firewall", layerKey: "p_firewall" },
];

export const ANALYSIS_PERMS_CONFIG = [
  { name: "Analysis", key: "analysis_view" },
  { name: "Bom Report", key: "bom_report_view" },
  { name: "Layer Data Report", key: "layer_data_view" },
  { name: "DP Customer Tagging Report", key: "customer_tagging_view" },
  {
    name: (
      <>
        Customer Tagging<br></br>Progress Report
      </>
    ),
    key: "customer_tagging_completion_view",
  },
  {
    name: (
      <>
        Customer Tagging Progress<br></br>Report - Daily Activity
      </>
    ),
    key: "customer_tagging_progress_view",
  },
  {
    name: "Alert Report",
    key: "alert_report_view",
  },
  {
    name: "FSA Home Pass Download",
    key: "fsa_hp_data_download",
    isLastRow: true, // do not show divider if last row
  },
];

export const MIN_PERM_WIDTH = 545;
export const MIN_PERM_FIELD_WIDTH = 130;

/**
 * Parent:
 *    UserAdminForm
 */
const UserPermissions = ({
  userId,
  isSuperUser,
  userPermissions,
  role,
  onSubmit,
  goBack,
}) => {
  const dispatch = useDispatch();
  const { isFetching, data = [] } = useQuery("userRoles", fetchUserRoles);

  const { mutate, isLoading } = useMutation(updateUserPerm, {
    onSuccess: (res) => {
      dispatch(
        addNotification({
          type: "success",
          title: "User Permissions",
          text: "User permissions updated successfully",
        })
      );
      onSubmit(res);
    },
    onError: (err) => {
      const { fieldList, messageList } = parseErrorMessagesWithFields(err);
      for (let index = 0; index < fieldList.length; index++) {
        const field = fieldList[index];
        const errorMessage = messageList[index];
        dispatch(
          addNotification({
            type: "error",
            title: field,
            text: errorMessage,
          })
        );
      }
    },
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    control,
    reset,
  } = useForm({
    defaultValues: { ...userPermissions, role_id: role },
  });

  const handlePermissionSubmit = useCallback(
    (data) => {
      if (isDirty) {
        let newData;
        if (!!data.role_id) {
          newData = { role_id: data.role_id };
        } else {
          newData = {
            ...data,
            id: undefined,
            role_id: undefined,
            created_by: undefined,
            created_on: undefined,
            updated_on: undefined,
            name: undefined,
          };
        }
        mutate({ userId, data: newData });
      } else {
        onSubmit(userPermissions);
      }
    },
    [isDirty]
  );

  const handleRoleChange = useCallback(
    (newValue) => {
      if (newValue) {
        const currRole = find(data, ["id", newValue]);

        reset(
          {
            ...currRole,
            role_id: newValue,
            created_by: undefined,
            created_on: undefined,
            updated_on: undefined,
          },
          { keepDirty: true }
        );
      }
    },
    [data, reset]
  );

  const disabledAll = !!watch("role_id");

  if (isSuperUser) {
    return (
      <Box p={4}>
        <Stack minHeight={400} alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            You don't required any permissions.
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" pt={4}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<ArrowBackIosIcon />}
            onClick={goBack}
          >
            Back
          </Button>
          <LoadingButton
            variant="outlined"
            color="success"
            type="submit"
            endIcon={<ArrowForwardIosIcon />}
            onClick={() => onSubmit(userPermissions)}
          >
            Next
          </LoadingButton>
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      p={4}
      pt={2}
      component="form"
      onSubmit={handleSubmit(handlePermissionSubmit)}
      sx={{
        overflow: "auto",
      }}
    >
      <Stack
        flex={1}
        p={4}
        justifyContent="center"
        sx={{ width: "50%", margin: "0 auto" }}
      >
        {isFetching ? (
          <Skeleton animation="wave" height={90} />
        ) : (
          <FormSelect
            label="User Role"
            name="role_id"
            control={control}
            options={data || []}
            labelKey="name"
            valueKey="id"
            error={!!errors.role_id}
            helperText={errors.role_id?.message}
            isClearable
            onChange={handleRoleChange}
          />
        )}
      </Stack>
      <PermissionHeader>General</PermissionHeader>
      <Divider />
      <Stack
        spacing={2}
        direction={{ md: "row", xs: "column" }}
        minHeight={54}
        justifyContent="center"
      >
        <PermissionLabel>User</PermissionLabel>
        <Box minWidth={MIN_PERM_WIDTH}>
          <Box>
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="View"
              name="user_view"
              control={control}
              error={!!errors.user_view}
              helperText={errors.user_view?.message}
              color="secondary"
              disabled={disabledAll}
            />
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="Add"
              name="user_add"
              control={control}
              error={!!errors.user_add}
              helperText={errors.user_add?.message}
              color="secondary"
              disabled={disabledAll}
            />
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="Edit"
              name="user_edit"
              control={control}
              error={!!errors.user_edit}
              helperText={errors.user_edit?.message}
              color="secondary"
              disabled={disabledAll}
            />
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="Download"
              name="user_download"
              control={control}
              error={!!errors.user_download}
              helperText={errors.user_download?.message}
              color="secondary"
              disabled={disabledAll}
            />
          </Box>
          <Box>
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="Upload"
              name="user_upload"
              control={control}
              error={!!errors.user_upload}
              helperText={errors.user_upload?.message}
              color="secondary"
              disabled={disabledAll}
            />
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="Reset password"
              name="user_reset_pass"
              control={control}
              error={!!errors.user_reset_pass}
              helperText={errors.user_reset_pass?.message}
              color="secondary"
              disabled={disabledAll}
            />
          </Box>
        </Box>
      </Stack>
      <Divider />
      <Stack
        spacing={2}
        direction={{ md: "row", xs: "column" }}
        minHeight={54}
        justifyContent="center"
      >
        <PermissionLabel>Dashboards</PermissionLabel>
        <Stack
          flexDirection="row"
          minWidth={MIN_PERM_WIDTH}
          alignItems="center"
        >
          {AVAILABLE_DASHBOARDS.map((dashConf) => {
            const { label, permission_key } = dashConf;
            return (
              <FormCheckbox
                sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
                label={label}
                name={permission_key}
                control={control}
                error={!!errors[permission_key]}
                helperText={errors[permission_key]?.message}
                color="secondary"
                disabled={disabledAll}
              />
            );
          })}
        </Stack>
      </Stack>
      <Divider />
      <Stack
        spacing={2}
        direction={{ md: "row", xs: "column" }}
        minHeight={54}
        justifyContent="center"
      >
        <PermissionLabel>Region</PermissionLabel>
        <Box minWidth={MIN_PERM_WIDTH}>
          <Box>
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="View"
              name="region_view"
              control={control}
              error={!!errors.region_view}
              helperText={errors.region_view?.message}
              color="secondary"
              disabled={disabledAll}
            />
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="Add"
              name="region_add"
              control={control}
              error={!!errors.region_add}
              helperText={errors.region_add?.message}
              color="secondary"
              disabled={disabledAll}
            />
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="Edit Details"
              name="region_edit"
              control={control}
              error={!!errors.region_edit}
              helperText={errors.region_edit?.message}
              color="secondary"
              disabled={disabledAll}
            />
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="Edit Location"
              name="region_edit_location"
              control={control}
              error={!!errors.region_edit_location}
              helperText={errors.region_edit_location?.message}
              color="secondary"
              disabled={disabledAll}
            />
          </Box>
          <Box>
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="Delete"
              name="region_delete"
              control={control}
              error={!!errors.region_delete}
              helperText={errors.region_delete?.message}
              color="secondary"
              disabled={disabledAll}
            />
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="Download"
              name="region_download"
              control={control}
              error={!!errors.region_download}
              helperText={errors.region_download?.message}
              color="secondary"
              disabled={disabledAll}
            />
            <FormCheckbox
              sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
              label="Upload"
              name="region_upload"
              control={control}
              error={!!errors.region_upload}
              helperText={errors.region_upload?.message}
              color="secondary"
              disabled={disabledAll}
            />
          </Box>
        </Box>
      </Stack>

      <PermissionHeader>Ticket</PermissionHeader>
      <Divider />
      <Stack
        spacing={2}
        direction={{ md: "row", xs: "column" }}
        minHeight={54}
        justifyContent="center"
      >
        <PermissionLabel>Ticket</PermissionLabel>
        <Stack
          flexDirection="row"
          minWidth={MIN_PERM_WIDTH}
          alignItems="center"
        >
          <FormCheckbox
            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
            label="View"
            name="ticket_view"
            control={control}
            error={!!errors.ticket_view}
            helperText={errors.ticket_view?.message}
            color="secondary"
            disabled={disabledAll}
          />
          <FormCheckbox
            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
            label="Add"
            name="ticket_add"
            control={control}
            error={!!errors.ticket_add}
            helperText={errors.ticket_add?.message}
            color="secondary"
            disabled={disabledAll}
          />
          <FormCheckbox
            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
            label="Edit Details"
            name="ticket_edit"
            control={control}
            error={!!errors.ticket_edit}
            helperText={errors.ticket_edit?.message}
            color="secondary"
            disabled={disabledAll}
          />
          <FormCheckbox
            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
            label="Edit Location"
            name="ticket_edit_location"
            control={control}
            error={!!errors.ticket_edit_location}
            helperText={errors.ticket_edit_location?.message}
            color="secondary"
            disabled={disabledAll}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack
        spacing={2}
        direction={{ md: "row", xs: "column" }}
        minHeight={54}
        justifyContent="center"
      >
        <PermissionLabel>Workorders</PermissionLabel>
        <Stack
          flexDirection="row"
          minWidth={MIN_PERM_WIDTH}
          alignItems="center"
        >
          <FormCheckbox
            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
            label="View"
            name="ticket_workorder_view"
            control={control}
            error={!!errors.ticket_workorder_view}
            helperText={errors.ticket_workorder_view?.message}
            color="secondary"
            disabled={disabledAll}
          />
          <FormCheckbox
            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
            label="Add"
            name="ticket_workorder_add"
            control={control}
            error={!!errors.ticket_workorder_add}
            helperText={errors.ticket_workorder_add?.message}
            color="secondary"
            disabled={disabledAll}
          />
          <FormCheckbox
            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
            label="Edit Details"
            name="ticket_workorder_edit"
            control={control}
            error={!!errors.ticket_workorder_edit}
            helperText={errors.ticket_workorder_edit?.message}
            color="secondary"
            disabled={disabledAll}
          />
          <FormCheckbox
            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
            label="Edit Location"
            name="ticket_workorder_edit_location"
            control={control}
            error={!!errors.ticket_workorder_edit_location}
            helperText={errors.ticket_workorder_edit_location?.message}
            color="secondary"
            disabled={disabledAll}
          />
        </Stack>
      </Stack>

      <PermissionHeader>Analysis</PermissionHeader>
      <Divider />
      {ANALYSIS_PERMS_CONFIG.map((permConf) => {
        return (
          <React.Fragment key={permConf.key}>
            <Stack
              spacing={2}
              direction={{ md: "row", xs: "column" }}
              minHeight={54}
              justifyContent="center"
            >
              <PermissionLabel>{permConf.name}</PermissionLabel>
              <Stack
                flexDirection="row"
                minWidth={MIN_PERM_WIDTH}
                alignItems="center"
              >
                <FormCheckbox
                  label="View"
                  name={permConf.key}
                  control={control}
                  error={!!get(errors, permConf.name)}
                  helperText={!!get(errors, [permConf.name, "message"])}
                  color="secondary"
                  disabled={disabledAll}
                />
              </Stack>
            </Stack>
            {permConf.isLastRow ? null : <Divider />}
          </React.Fragment>
        );
      })}

      <PermissionHeader>Trace Back</PermissionHeader>
      <Divider />
      <Stack
        spacing={2}
        direction={{ md: "row", xs: "column" }}
        minHeight={54}
        justifyContent="center"
      >
        <PermissionLabel>Trace Back</PermissionLabel>
        <Stack
          flexDirection="row"
          minWidth={MIN_PERM_WIDTH}
          alignItems="center"
        >
          <FormCheckbox
            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
            label="View"
            name={`trace_back_view`}
            control={control}
            error={has(errors, `trace_back_view`)}
            helperText={get(errors, `trace_back_view.message`, "")}
            color="secondary"
            disabled={disabledAll}
          />

          <FormCheckbox
            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
            label="Download"
            name={`trace_back_download`}
            control={control}
            error={has(errors, `trace_back_download`)}
            helperText={get(errors, `trace_back_download.message`, "")}
            color="secondary"
            disabled={disabledAll}
          />
        </Stack>
      </Stack>

      <PermissionHeader>CATV</PermissionHeader>
      <Divider />
      <Stack
        spacing={2}
        direction={{ md: "row", xs: "column" }}
        minHeight={54}
        justifyContent="center"
      >
        <PermissionLabel>Catv User</PermissionLabel>
        <Stack
          flexDirection="row"
          minWidth={MIN_PERM_WIDTH}
          alignItems="center"
        >
          <FormCheckbox
            label="Catv User"
            name="is_catv_user"
            control={control}
            error={!!errors.is_catv_user}
            helperText={errors.is_catv_user?.message}
            color="secondary"
            disabled={disabledAll}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack
        spacing={2}
        direction={{ md: "row", xs: "column" }}
        minHeight={54}
        justifyContent="center"
      >
        <PermissionLabel>Alert</PermissionLabel>
        <Stack
          flexDirection="row"
          minWidth={MIN_PERM_WIDTH}
          alignItems="center"
        >
          <FormCheckbox
            label="View List"
            name="alert_list_view"
            control={control}
            error={!!errors.alert_list_view}
            helperText={errors.alert_list_view?.message}
            color="secondary"
            disabled={disabledAll}
          />
          <FormCheckbox
            label="Can Close"
            name="alert_can_close"
            control={control}
            error={!!errors.alert_can_close}
            helperText={errors.alert_can_close?.message}
            color="secondary"
            disabled={disabledAll}
          />
        </Stack>
      </Stack>
      <Divider />

      <PermissionHeader>Planning</PermissionHeader>
      <Divider />
      <Stack
        spacing={2}
        direction={{ md: "row", xs: "column" }}
        minHeight={54}
        justifyContent="center"
      >
        <PermissionLabel>Planning</PermissionLabel>
        <Stack
          flexDirection="row"
          minWidth={MIN_PERM_WIDTH}
          alignItems="center"
        >
          <FormCheckbox
            label="View"
            name="planning_view"
            control={control}
            error={!!errors.planning_view}
            helperText={errors.planning_view?.message}
            color="secondary"
            disabled={disabledAll}
          />
        </Stack>
      </Stack>
      <Divider />
      {USER_LAYER_PERMS_CONFIG.map((userPermConf) => {
        const { name, layerKey } = userPermConf;

        return (
          <React.Fragment key={layerKey}>
            <Stack
              spacing={2}
              direction={{ md: "row", xs: "column" }}
              minHeight={54}
              justifyContent="center"
            >
              <PermissionLabel>{name}</PermissionLabel>
              <Box minWidth={MIN_PERM_WIDTH}>
                <Box>
                  <FormCheckbox
                    sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
                    label="View"
                    name={`${layerKey}_view`}
                    control={control}
                    error={has(errors, `${layerKey}_view`)}
                    helperText={get(errors, `${layerKey}_view.message`, "")}
                    color="secondary"
                    disabled={disabledAll}
                  />
                  <FormCheckbox
                    sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
                    label="Add"
                    name={`${layerKey}_add`}
                    control={control}
                    error={has(errors, `${layerKey}_add`)}
                    helperText={get(errors, `${layerKey}_add.message`, "")}
                    color="secondary"
                    disabled={disabledAll}
                  />
                  <FormCheckbox
                    sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
                    label="Edit Details"
                    name={`${layerKey}_edit`}
                    control={control}
                    error={has(errors, `${layerKey}_edit`)}
                    helperText={get(errors, `${layerKey}_edit.message`, "")}
                    color="secondary"
                    disabled={disabledAll}
                  />
                  <FormCheckbox
                    sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
                    label="Edit Location"
                    name={`${layerKey}_edit_location`}
                    control={control}
                    error={has(errors, `${layerKey}_edit_location`)}
                    helperText={get(
                      errors,
                      `${layerKey}_edit_location.message`,
                      ""
                    )}
                    color="secondary"
                    disabled={disabledAll}
                  />
                </Box>
                <Box>
                  <FormCheckbox
                    sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
                    label="Delete"
                    name={`${layerKey}_delete`}
                    control={control}
                    error={has(errors, `${layerKey}_delete`)}
                    helperText={get(errors, `${layerKey}_delete.message`, "")}
                    color="secondary"
                    disabled={disabledAll}
                  />
                  <FormCheckbox
                    sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
                    label="Download"
                    name={`${layerKey}_download`}
                    control={control}
                    error={has(errors, `${layerKey}_download`)}
                    helperText={get(errors, `${layerKey}_download.message`, "")}
                    color="secondary"
                    disabled={disabledAll}
                  />
                  <FormCheckbox
                    sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
                    label="Upload"
                    name={`${layerKey}_upload`}
                    control={control}
                    error={has(errors, `${layerKey}_upload`)}
                    helperText={get(errors, `${layerKey}_upload.message`, "")}
                    color="secondary"
                    disabled={disabledAll}
                  />
                </Box>
              </Box>
            </Stack>
            <Divider />
          </React.Fragment>
        );
      })}

      <Stack flex={1} direction="row" justifyContent="space-between" pt={4}>
        <Button
          variant="outlined"
          color="error"
          startIcon={<ArrowBackIosIcon />}
          onClick={goBack}
        >
          Back
        </Button>
        <LoadingButton
          variant="outlined"
          color="success"
          type="submit"
          endIcon={<ArrowForwardIosIcon />}
          loading={isLoading}
        >
          Next
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export const PermissionLabel = (props) => {
  return (
    <Typography
      variant="h6"
      component="div"
      color="primary"
      mt="10px"
      minWidth={240}
    >
      {props.children}
    </Typography>
  );
};

export const PermissionHeader = (props) => {
  return (
    <Typography
      variant="h5"
      gutterBottom
      component="div"
      textAlign="center"
      mt={2.5}
    >
      {props.children}
    </Typography>
  );
};

export default UserPermissions;
