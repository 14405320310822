import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import size from "lodash/size";
import map from "lodash/map";
import get from "lodash/get";
import countBy from "lodash/countBy";
import isNull from "lodash/isNull";
import filter from "lodash/filter";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import TicketWorkOrderList from "./components/TicketWorkOrderList";
import { GisElementTableLoader } from "planning/GisMap/components/GisMapPopups/GisMapPopupLoader";
import GisMapPopups from "planning/GisMap/components/GisMapPopups";
import TableHeader from "planning/GisMap/components/ElementDetailsTable/TableHeader";
import ConfirmDialog from "components/common/ConfirmDialog";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";

import {
  fetchTicketWorkorderDataThunk,
  ticketAcceptAllWo,
  ticketStatusUpdate,
} from "planning/data/ticket.services";
import { getPlanningTicketData } from "planning/data/planningGis.selectors";
import { getPlanningPage } from "utils/url.constants";
import { addNotification } from "redux/reducers/notification.reducer";
import { NOTIFICATION_TYPE } from "components/common/Notification/Notification";
import { parseErrorMessagesWithFields } from "utils/api.utils";
import { checkUserPermission } from "redux/selectors/auth.selectors";
import { workOrderStatusTypes } from "utils/constant";

import NetworkTicketCollapsibleWOList from "./components/NetworkTicketCollapsibleWOList";
import CustomerTicketCollapsibleWOList from "./components/CustomerTicketCollapsibleWOList";

/**
 * Parent
 *  PlanningPage
 */
const TicketSideBar = React.memo(({ ticketId }) => {
  const [statusFilter, setStatusFilter] = useState(null);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [showCompletePopup, setShowCompletePopup] = useState(false);

  const [minimized, setMinimized] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const canTicketStatusUpdate = useSelector(checkUserPermission("ticket_edit"));

  const ticketData = useSelector(getPlanningTicketData);
  const { isLoading, isError, name, work_orders, ticket_type } = ticketData;

  // fetch ticket details
  useEffect(() => {
    dispatch(fetchTicketWorkorderDataThunk(ticketId));
  }, [ticketId]);

  const { mutate: acceptMutation, isLoading: acceptLoading } = useMutation(
    ticketAcceptAllWo,
    {
      onSuccess: (res) => {
        handleHidePopup();
        dispatch(fetchTicketWorkorderDataThunk(ticketId));

        dispatch(
          addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            title: "Accept all workorders",
            text: `${res.wo_update_count} updated successfully.`,
          })
        );
      },
      onError: (err) => {
        handleHidePopup();
        const { fieldList, messageList } = parseErrorMessagesWithFields(err);
        for (let index = 0; index < fieldList.length; index++) {
          const field = fieldList[index];
          const errorMessage = messageList[index];
          dispatch(
            addNotification({
              type: "error",
              title: field,
              text: errorMessage,
            })
          );
        }
      },
    }
  );

  const { mutate: ticketStatusUpdateMutation, isLoading: statusLoading } =
    useMutation(ticketStatusUpdate, {
      onSuccess: (res) => {
        handleHidePopup();
        dispatch(
          addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            title: "Ticket Status Update",
            text: `Ticket status updated successfully.`,
          })
        );
      },
      onError: (err) => {
        handleHidePopup();
        const { fieldList, messageList } = parseErrorMessagesWithFields(err);
        for (let index = 0; index < fieldList.length; index++) {
          const field = fieldList[index];
          const errorMessage = messageList[index];
          dispatch(
            addNotification({
              type: "error",
              title: field,
              text: errorMessage,
            })
          );
        }
      },
    });

  const handleCloseTicketSideBar = useCallback(() => {
    navigate(getPlanningPage());
  }, []);

  const handlePopupMinimize = useCallback(() => {
    setMinimized((value) => !value);
  }, [setMinimized]);

  const handleAcceptAll = useCallback(() => {
    acceptMutation(ticketId);
  }, []);

  const handleStatusUpdate = useCallback(() => {
    ticketStatusUpdateMutation({ status: "C", ticketId });
  }, []);

  const handleFilterClick = useCallback(
    (newStatus) => () => {
      setStatusFilter((currStatus) =>
        currStatus === newStatus ? null : newStatus
      );
    },
    []
  );

  const handleShowVerifyPopup = useCallback(() => {
    setShowVerifyPopup(true);
  }, []);

  const handleCompletePopup = useCallback(() => {
    setShowCompletePopup(true);
  }, []);

  const handleHidePopup = useCallback(() => {
    setShowVerifyPopup(false);
    setShowCompletePopup(false);
  }, []);

  if (isLoading) {
    return <GisElementTableLoader />;
  }

  const hasWorkorders = size(work_orders);
  const showAcceptAllBtn = hasWorkorders;
  const countByStatus = countBy(work_orders, "status");

  // filter work orders according to statusFilter
  const filteredWorkOrders = hasWorkorders
    ? isNull(statusFilter)
      ? [...work_orders]
      : filter(work_orders, ["status", statusFilter])
    : [];

  return (
    <GisMapPopups dragId="ticket-wo">
      <Box minWidth="500px" maxWidth="650px">
        {/* Table header */}
        <TableHeader
          title={name}
          minimized={minimized}
          handlePopupMinimize={handlePopupMinimize}
          handleCloseDetails={handleCloseTicketSideBar}
        />
        {isError ? (
          <Box p={2}>
            <Typography variant="h5">
              Error occured while fetching data
            </Typography>
          </Box>
        ) : minimized ? null : (
          <>
            {showAcceptAllBtn ? (
              <>
                <Box p={1}>
                  <ButtonGroup variant="contained" color="text" fullWidth>
                    {map(workOrderStatusTypes, (wStatus) => {
                      const selected = statusFilter === wStatus.value;
                      const chipLabel = `${wStatus.label} (${get(
                        countByStatus,
                        wStatus.value,
                        0
                      )})`;
                      return (
                        <Button
                          variant={selected ? "contained" : undefined}
                          color={selected ? wStatus.color : undefined}
                          key={wStatus.value}
                          onClick={handleFilterClick(wStatus.value)}
                        >
                          {chipLabel}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </Box>
                <Stack
                  p={1}
                  pt={0}
                  display="flex"
                  justifyContent="flex-end"
                  spacing={1}
                  direction="row"
                >
                  {canTicketStatusUpdate ? (
                    <LoadingButton
                      variant="outlined"
                      startIcon={<DoneIcon />}
                      color="secondary"
                      onClick={handleCompletePopup}
                    >
                      Complete Ticket
                    </LoadingButton>
                  ) : null}
                  <LoadingButton
                    variant="outlined"
                    startIcon={<DoneAllIcon />}
                    color="secondary"
                    onClick={handleShowVerifyPopup}
                  >
                    Verify All
                  </LoadingButton>
                </Stack>
                <Divider />
              </>
            ) : null}
            <Box
              className="ticket-sidebar-content"
              maxHeight="72vh"
              overflow="auto"
            >
              {ticket_type === "C" ? (
                <CustomerTicketCollapsibleWOList
                  workOrderList={filteredWorkOrders}
                  isFilterActive={!!statusFilter}
                />
              ) : (
                <NetworkTicketCollapsibleWOList
                  workOrderList={filteredWorkOrders}
                  isFilterActive={!!statusFilter}
                />
              )}
            </Box>
            <ConfirmDialog
              show={showCompletePopup}
              onClose={handleHidePopup}
              onConfirm={handleStatusUpdate}
              isLoading={statusLoading}
              confirmText="Complete Ticket"
              title="Complete Ticket"
              text="Are you sure you want to complete ticket ?"
            />
            <ConfirmDialog
              show={showVerifyPopup}
              onClose={handleHidePopup}
              onConfirm={handleAcceptAll}
              isLoading={acceptLoading}
              confirmText="Verify All"
              title="Verify All"
              text="Are you sure you want to verify all ?"
            />
          </>
        )}
      </Box>
    </GisMapPopups>
  );
});

export default TicketSideBar;
