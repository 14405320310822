import Api from "utils/api.utils";
import {
  apiGetAlertActionsList,
  apiGetAlertDetails,
  apiGetAlertList,
  apiPostAlertAdd,
  apiPostAlertBulkUploadAction,
} from "utils/url.constants";

export const fetchAlertList = async ({ queryKey }) => {
  const res = await Api.get(apiGetAlertList(queryKey[1]));
  return res.data;
};

export const fetchAlertDetails = async ({ queryKey }) => {
  const [_, alertId] = queryKey;
  const res = await Api.get(apiGetAlertDetails(alertId));
  return res.data;
};

export const fetchAlertActionsList = async ({ queryKey }) => {
  const [_, alertId] = queryKey;
  const res = await Api.get(apiGetAlertActionsList(alertId));
  return res.data;
};

export const addAlertService = async (data) => {
  const res = await Api.post(apiPostAlertAdd(), data);
  return res.data;
};

export const uploadBulkAlertActions = async (data) => {
  const res = await Api.post(apiPostAlertBulkUploadAction(), data, null, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
  return res.data;
};
